import React, { useState } from "react";
import "./services.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import service_1 from "../../assets/construction/buildingCons.png";
import service_2 from "../../assets/construction/renoa.png";
import service_3 from "../../assets/construction/projectMang.png";
import service_4 from "../../assets/construction/materials.png";

import logs from "../../assets/images/logs.png";

import images11 from "../../assets/materials/images11.jpeg";
import images13 from "../../assets/materials/images13.jpeg";
import images7 from "../../assets/materials/images7.jpeg";
import images10 from "../../assets/materials/images10.png";
import images9 from "../../assets/materials/images9.jpeg";
import images8 from "../../assets/materials/images8.jpeg";
import images4 from "../../assets/materials/images4.jpeg";
import images6 from "../../assets/materials/images6.jpeg";
import images3 from "../../assets/materials/images3.jpeg";
import images15 from "../../assets/materials/images15.jpeg";
import images12 from "../../assets/materials/images12.jpeg";

const allImages = [
  images11,
  images13,
  images7,
  images10,
  images9,
  images8,
  images4,
  images6,
  images3,
  images15,
  images12,
];

function Services() {
  const { t } = useTranslation();
  let location = useLocation();
  const [tapActive, setTapActive] = useState(1);

  const setTapActiveClick = (tatNumber) => {
    setTapActive(tatNumber);
    console.log(tatNumber);
  };
  return (
    <div className="services">
      <div className="services_container">
        <h3>
          {location.pathname !== "/ar" ? t("ozkaya") : t("services")}{" "}
          <span>
            {location.pathname !== "/ar" ? t("services") : t("ozkaya")}
          </span>
        </h3>

        <div className="services_tap">
          <div className="tap" id="1" onClick={() => setTapActiveClick(1)}>
            <span className={tapActive == 1 ? "tapSpan_active" : "tapSpan"}>
              {t("BuildingMaterialsTrading")}
            </span>
          </div>
          <div className="tap" id="2" onClick={() => setTapActiveClick(2)}>
            <span className={tapActive == 2 ? "tapSpan_active" : "tapSpan"}>
              {t("LogisticsNetwork")}
            </span>
          </div>
          <div className="tap" id="3" onClick={() => setTapActiveClick(3)}>
            <span className={tapActive == 3 ? "tapSpan_active" : "tapSpan"}>
              {t("SupplyDistribute")}
            </span>
          </div>
          <div className="tap" id="4" onClick={() => setTapActiveClick(4)}>
            <span className={tapActive == 4 ? "tapSpan_active" : "tapSpan"}>
              {t("TransitTrading")}
            </span>
          </div>
        </div>
        <div
          className={
            tapActive == 1
              ? location.pathname !== "/ar"
                ? "services_section"
                : "services_section_ar"
              : "services_section_hide"
          }
        >
          <div className="content">
            <p>{t("BuildingMaterialsTradingText")}</p>
            <div className="services_image_content">
              <div className="content_allImages">
                {allImages.map((image, index) => (
                  <img src={image} alt={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            tapActive == 2
              ? location.pathname !== "/ar"
                ? "services_section"
                : "services_section_ar"
              : "services_section_hide"
          }
        >
          <p>{t("LogisticsNetworkTxt")}</p>
          <div className="services_image_content">
            <img src={service_1} alt="service_1" />
          </div>
        </div>
        <div
          className={
            tapActive == 3
              ? location.pathname !== "/ar"
                ? "services_section"
                : "services_section_ar"
              : "services_section_hide"
          }
        >
          <p>{t("SupplyDistributeTxt")}</p>
          <div className="services_image_content">
            <img src={service_2} alt="service_2" />
          </div>
        </div>
        <div
          className={
            tapActive == 4
              ? location.pathname !== "/ar"
                ? "services_section"
                : "services_section_ar"
              : "services_section_hide"
          }
        >
          <p>{t("TransitTradingTxt")}</p>
          <div className="services_image_content">
            <img src={service_3} alt="service_3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
