import React, { useEffect, useState } from "react";
import "./navbar.css";
import Logo from "../../assets/images/logo-ozkaya-black.png";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import { Fade as Hamburger } from "hamburger-react";

function Navbar() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();
  const [isOpen, setOpen] = useState(false);

  const changeLagn = (event) => {
    event.preventDefault();
    if (location.pathname === "/ar") {
      i18n.changeLanguage("en"); // Change the language
      let path = `/`;
      navigate(path);
    } else {
      i18n.changeLanguage("ar"); // Change the language
      let path = `/ar`;
      navigate(path);
    }
  };

  const closeSide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.scrollY > 0) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="navbar">
      <div className="nav_container">
        <img src={Logo} alt="Ozkaya gida logo" className="nav_logo" />

        <div
          className={
            location.pathname !== "/ar" ? "nav_sections" : "nav_sections_ar"
          }
        >
          <div
            className={
              location.pathname !== "/ar" ? "nav_section" : "nav_section_ar"
            }
          >
            <Link to="home" smooth={true} duration={1000}>
              <p>{t("home")}</p>
            </Link>
          </div>
          <div
            className={
              location.pathname !== "/ar" ? "nav_section" : "nav_section_ar"
            }
          >
            <Link to="about" smooth={true} duration={1000}>
              <p>{t("about")}</p>
            </Link>
          </div>

          <div
            className={
              location.pathname !== "/ar" ? "nav_section" : "nav_section_ar"
            }
          >
            <Link to="services" smooth={true} duration={1000}>
              <p>{t("services")}</p>
            </Link>
          </div>
          <div
            className={
              location.pathname !== "/ar" ? "nav_section" : "nav_section_ar"
            }
          >
            <Link to="products" smooth={true} duration={1000}>
              <p>{t("products")}</p>
            </Link>
          </div>
          <div
            className={
              location.pathname !== "/ar" ? "nav_section" : "nav_section_ar"
            }
          >
            <Link to="Coproducts" smooth={true} duration={1000}>
              <p>{t("CoProducts")}</p>
            </Link>
          </div>
          <div
            className={
              location.pathname !== "/ar" ? "nav_section" : "nav_section_ar"
            }
          >
            <Link to="location" smooth={true} duration={1000}>
              <p>{t("location")}</p>
            </Link>
          </div>

          <div
            className={
              location.pathname !== "/ar" ? "nav_section" : "nav_section_ar"
            }
          >
            <Link to="contact" smooth={true} duration={1000}>
              <p>{t("contactUs")}</p>
            </Link>
          </div>
        </div>

        <div className="lang" onClick={changeLagn}>
          <LanguageIcon className="ico" />
          <p>{location.pathname !== "/ar" ? "en" : "عربي"}</p>
        </div>
      </div>
      <div className="hamburger">
        <Hamburger toggled={isOpen} toggle={setOpen} size={30} color="white" />
      </div>
      <div className="menu" style={{ display: isOpen ? "flex" : "none" }}>
        <div className="nav_sections_menu">
          <div className="nav_section_ar">
            <Link to="home" smooth={true} duration={1000} onClick={closeSide}>
              <p>{t("home")}</p>
            </Link>
          </div>
          <div className="nav_section_ar">
            <Link to="about" smooth={true} duration={1000} onClick={closeSide}>
              <p>{t("about")}</p>
            </Link>
          </div>

          <div className="nav_section_ar">
            <Link
              to="services"
              smooth={true}
              duration={1000}
              onClick={closeSide}
            >
              <p>{t("services")}</p>
            </Link>
          </div>
          <div className="nav_section_ar">
            <Link
              to="products"
              smooth={true}
              duration={1000}
              onClick={closeSide}
            >
              <p>{t("products")}</p>
            </Link>
          </div>

          <div className="nav_section_ar">
            <Link
              to="Coproducts"
              smooth={true}
              duration={1000}
              onClick={closeSide}
            >
              <p>{t("CoProducts")}</p>
            </Link>
          </div>
          <div className="nav_section_ar">
            <Link
              to="location"
              smooth={true}
              duration={1000}
              onClick={closeSide}
            >
              <p>{t("location")}</p>
            </Link>
          </div>
          <div className="nav_section_ar">
            <Link
              to="contact"
              smooth={true}
              duration={1000}
              onClick={closeSide}
            >
              <p>{t("contactUs")}</p>
            </Link>
          </div>
          <div className="lang_menu" onClick={changeLagn}>
            <LanguageIcon className="ico" />
            <p>{location.pathname === "/en" ? "en" : "عربي"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
