import React, { useState } from "react";
import "./products.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import products_1 from "../../assets/products/products_1.jpeg";
import products_2 from "../../assets/products/products_2.webp";
import products_3 from "../../assets/products/products_3.jpeg";
import products_4 from "../../assets/products/products_4.png";
import products_5 from "../../assets/products/products_5.webp";
import products_6 from "../../assets/products/products_6.webp";
import products_7 from "../../assets/products/products_7.jpeg";
import products_8 from "../../assets/products/products_8.jpeg";

function Products() {
  const { t } = useTranslation();
  let location = useLocation();
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const setImageAndOpen = (image) => {
    setOpenImage(true);
    setSelectedImage(image);
  };

  const projectsImage =
    location.pathname === "/ar"
      ? [
          {
            img: products_1,
            name: "عدد يديوية",
          },
          {
            img: products_2,
            name: "ابواب خشبية",
          },
          {
            img: products_3,
            name: "ابواب فولاذية",
          },
          {
            img: products_4,
            name: "ابواب المنيوم",
          },
          {
            img: products_5,
            name: "شبابيك المنيوم",
          },
          {
            img: products_6,
            name: "ثريات",
          },
          {
            img: products_7,
            name: "ابريز كهرباء",
          },
          {
            img: products_8,
            name: "امانات كهرباء",
          },
        ]
      : [
          {
            img: products_1,
            name: "Hand tools",
          },
          {
            img: products_2,
            name: "wooden doors",
          },
          {
            img: products_3,
            name: "steel doors",
          },
          {
            img: products_4,
            name: "Aluminum doors",
          },
          {
            img: products_5,
            name: "Aluminum windows",
          },
          {
            img: products_6,
            name: "Chandeliers",
          },
          {
            img: products_7,
            name: "Electricity switch",
          },
          {
            img: products_8,
            name: "circuit breaker",
          },
        ];

  return (
    <div className="products">
      <div className="products_container">
        <h3>
          {location.pathname !== "/ar" ? t("ozkaya") : t("CoProducts")}{" "}
          <span>
            {location.pathname !== "/ar" ? t("CoProducts") : t("ozkaya")}
          </span>
        </h3>
        <div className="Coprojects_container">
          {projectsImage.map((images, index) => (
            <div className="Coprojects_container_content">
              <img
                src={images["img"]}
                alt={index}
                onClick={() => setImageAndOpen(images["img"])}
              />
              <div className="Coprojects_container_name">
                <span>{images["name"]}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={openImage ? "projects_image_view" : "projects_image_view_no"}
        onClick={() => setOpenImage(false)}
      >
        <img src={selectedImage} alt="shoued_image" />
      </div>
    </div>
  );
}

export default Products;
