import React, { useState } from "react";
import "./products.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ozkayamesin from "../../assets/mersin/ozkayamesin.jpeg";
import building2 from "../../assets/mersin/building2.jpeg";
import mesinllogoinside from "../../assets/mersin/mesinllogoinside.jpeg";
import frombasbaal from "../../assets/mersin/frombasbaal.jpeg";
import building from "../../assets/mersin/building.jpeg";
import mesrinview from "../../assets/mersin/mesrinview.jpeg";
import mesinimagin from "../../assets/mersin/mesinimagin.jpeg";
import mesinlogobuilding from "../../assets/mersin/mesinlogobuilding.jpeg";
import nig from "../../assets/mersin/nig.jpeg";
import setroom from "../../assets/mersin/setroom.jpeg";
import kech from "../../assets/mersin/kech.jpeg";
import bathroom from "../../assets/mersin/bathroom.jpeg";
import kch2 from "../../assets/mersin/kch2.jpeg";
import kech3 from "../../assets/mersin/kech3.jpeg";
import metologo from "../../assets/mersin/metologo.jpeg";

import img1 from "../../assets/newprojects/img1.jpeg";
import img2 from "../../assets/newprojects/img2.jpeg";
import img3 from "../../assets/newprojects/img3.jpeg";
import img4 from "../../assets/newprojects/img4.jpeg";
import img5 from "../../assets/newprojects/img5.jpeg";

function Products() {
  const { t } = useTranslation();
  let location = useLocation();
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const setImageAndOpen = (image) => {
    setOpenImage(true);
    setSelectedImage(image);
  };

  const projectsImage = [
    // ozkayamesin,
    building2,
    building,
    // mesinllogoinside,
    frombasbaal,
    mesrinview,
    mesinimagin,
    // mesinlogobuilding,
    nig,
    setroom,
    kech,
    bathroom,
    kch2,
    kech3,
    // metologo,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  return (
    <div className="products">
      <div className="products_container">
        <h3>
          {location.pathname !== "/ar" ? t("ozkaya") : t("coProductsCo")}{" "}
          <span>
            {location.pathname !== "/ar" ? t("coProductsCo") : t("ozkaya")}
          </span>
        </h3>
        <div className="projects_container">
          {projectsImage.map((images, index) => (
            <img
              src={images}
              alt={index}
              onClick={() => setImageAndOpen(images)}
            />
          ))}
        </div>
      </div>
      <div
        className={openImage ? "projects_image_view" : "projects_image_view_no"}
        onClick={() => setOpenImage(false)}
      >
        <img src={selectedImage} alt="shoued_image" />
      </div>
    </div>
  );
}

export default Products;
