import React from "react";
import "./homeCom.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import consimg from "../../assets/construction/consimg.png";
import logoEN from "../../assets/images/logo-ozkaya.png";
import logoAR from "../../assets/images/logo-ozkaya.png";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";

function HomeCom() {
  let location = useLocation();
  const { t } = useTranslation();
  return (
    <div className="homeCom">
      <div
        className={
          location.pathname !== "/ar" ? "home_container" : "home_container_ar"
        }
      >
        <div
          className={
            location.pathname !== "/ar" ? "text_content" : "text_content_ar"
          }
        >
          {/* <h1>{t("ozkaya")}</h1>
          <h2>{t("food_trading_company")}</h2> */}
          <img
            src={location.pathname !== "/ar" ? logoEN : logoAR}
            alt="logo_en"
            className="logo_co"
          />
          <div className={location.pathname !== "/ar" ? "graph" : "graph_ar"}>
            <p>{t("homeGraph")}</p>
          </div>
          <Link to="contact" smooth={true} duration={1000}>
            <div className="btn_contact">
              <span>{t("contactUs")}</span>
            </div>
          </Link>
        </div>
        <div className="image_content">
          <img src={consimg} alt="foods" />
        </div>
      </div>
    </div>
  );
}

export default HomeCom;
